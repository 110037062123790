import { Auth0Provider } from "@auth0/auth0-react"
import config from "../config"

import '../styles/globals.css'

function MyApp({ Component, pageProps }) {

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      redirectUri={(typeof window === 'undefined') ? "" : window.location.origin}
    >
      <Component {...pageProps} />
    </Auth0Provider>
  )
}

export default MyApp
