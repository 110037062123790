class Config {
    constructor() {
        // Define the variables
        var requiredEnvVars = new Map()
        requiredEnvVars.set(
            "API_BASE_URL", process.env.NEXT_PUBLIC_API_BASE_URL
        )
        requiredEnvVars.set(
            "AUTH0_DOMAIN", process.env.NEXT_PUBLIC_AUTH0_DOMAIN
        )
        requiredEnvVars.set(
            "AUTH0_CLIENT_ID", process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID
        )

        // Check the variables
        let errorCount = 0
        for (let [varName, varValue] of requiredEnvVars.entries()){
            if (typeof varValue === "undefined") {
                console.error("Missing environment variable: " + varName)
                errorCount++
            } else {
                this[varName] = varValue
            }
        }
        if(errorCount === 0) {
            console.debug("All environment checks passed")
        }

        // Make this object read-only
        Object.freeze(this)
    }
}

const config = new Config()

export default config
